<template>
    <div class="plans-and-products">
      <div class="container">
        <div class="row">
          <!-- For Institutions -->
          <div class="col-md-3 box">
            <h3>For Institutions</h3>
            <p>EduSay Web App is the English teaching support solution your educators will love.</p>
            <button class="btn btn-primary" @click="registerInterest">Register interest</button>
          </div>
          <!-- For Students -->
          <div class="col-md-3 box">
            <h3>For Students</h3>
            <p>SayIt Apps are available for individual learners, providing a safe practice space.</p>
            <button class="btn btn-primary" @click="downloadNow">Download now</button>
          </div>
          <!-- For Businesses -->
          <div class="col-md-3 box">
            <h3>For Businesses</h3>
            <p>Valuable Learning Insight to improve confidence and communication.</p>
            <button class="btn btn-primary" @click="bookADemo">Book a demo</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      registerInterest() {
        // Logic to handle interest registration
      },
      downloadNow() {
        // Logic to handle download
      },
      bookADemo() {
        // Logic to handle demo booking
      }
    }
  };
  </script>
  
  <style scoped>
  .plans-and-products .btn {
    margin-top: 20px;
    background-color: #17204a;
    border: none;
  }
  .box{
    border: solid 1px #ccc;
    padding: 20px;
    margin: 20px;
    border-radius: 5px;
  }
  </style>
  