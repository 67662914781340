<template>
    <footer class="footer">
      <div class="container text-center">
        <p>Copyright © 2019 - 2024 Prospeech Technologies Limited. All Rights Reserved.</p>
        <p>
          <router-link to="/privacy-policy">Privacy Policy</router-link> | 
          <router-link to="/do-not-sell-my-info">Do not sell my personal information</router-link>
        </p>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterBar'
  };
  </script>
  
  <style scoped>
  .footer {
    padding: 20px 0;
    background-color: #f8f9fa; /* Light gray background, adjust if needed */
    color: #333; /* Dark text color, adjust if needed */
    margin-top: 20px; /* Space from the content above */
  }
  
  .container {
    max-width: 1140px;
    margin: auto;
  }
  
  .text-center {
    text-align: center;
  }
  </style>
  