<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img src="@/assets/logo.png" alt="ProSpeech" class="logo" />
      </a>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a href="https://www.phona.co.uk/" target="_blank" class="nav-link">Student</a>
          </li>
          <li class="nav-item dropdown-teachers">
            <router-link to="/teachers" class="nav-link">Teachers</router-link>
            <div class="dropdown-content-teachers">
              <div class="container-fluid">
                <div class="row">
                  <!-- Left Section (8 cols) -->
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="box">
                          <h4 class="title">What is EduSay?</h4>
                          <ul>
                            <li>Teachers and educators</li>
                            <li>Individuals</li>
                            <li>Schools and universities</li>
                            <li>Business</li>
                          </ul>
                          <button class="btn btn-light">EduSay plans and pricing</button>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="box">
                          <h4 class="title">Shop For</h4>
                          <ul>
                            <li>Business</li>
                            <li>Student and Teachers</li>
                            <li>Personal</li>
                            <li>Government</li>
                            <li>View all products</li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="box">
                          <h4 class="title">Solutions</h4>
                          <ul>
                            <li>Data insight and audiences</li>
                            <li>Personalised insight and engagement</li>
                            <li>Content management</li>
                            <li>Machine powered learning</li>
                            <li>View all products</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Right Section (4 cols) -->
                  <div class="col-md-4 text-center">
                    <img src="@/assets/EduSay_Logo.png" alt="EduSay" class="dropdown-logo"><br>
                    <button style="margin-top: 20px;" class="btn btn-light">Register interest</button>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown-full">
            <router-link to="/solutions" class="nav-link">Solutions</router-link>
            <!-- Dropdown Content for Solutions -->
            <div class="dropdown-content-full">
              <div class="container-fluid">
                <div class="row">
                  <!-- Left half -->
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="box">
                          <h4 class="title">Solutions</h4>
                          <ul>
                            <li>Data insights and audiences</li>
                            <li>Personalised insight and engagement</li>
                            <li>Content management</li>
                            <li>Machine learning</li>
                          </ul>
                          <button class="btn btn-light">Request a demo</button>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="box">
                          <h4 class="title">Shop For</h4>
                          <ul>
                            <li>Business</li>
                            <li>Student and Teachers</li>
                            <li>Personal</li>
                            <li>Government</li>
                            <li>View all products</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Right half -->
                  <div class="col-md-6 text-center">
                    <img src="@/assets/EduSay_Logo.png" alt="EduSay" class="dropdown-logo"><br>
                    <button style="margin-top: 20px;" class="btn btn-light">Register interest</button>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item">
            <router-link to="/plans-and-products" class="nav-link">Plan and products</router-link>
          </li>
        </ul>
        <div class="dropdown">
          <!-- Existing dropdown -->
          <i class="bi bi-grid-3x3-gap-fill dropdown apps-icon" data-bs-toggle="dropdown" aria-expanded="false"></i>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li><img style="height: 40px;" src="@/assets/sayit-icon.png"><br><span>Edu Say</span></li>
            <li><img style="height:40px;" src="@/assets/sayit-icon.png"><br><span>Prospeechtechnologies.co.uk</span></li>
          </ul>
        </div>
        <a href="https://prospeechtechnologies.co.uk/admin" class="btn btn-outline-success" type="submit">Sign in</a>
      </div>
    </div>
  </nav>
</template>




<style scoped>
.navbar {
  border-bottom: 5px solid #17204a;
}
.logo {
  height: 20px;
}
.apps-icon, .nav-link {
  font-size: 16px; /* Adjust font size for consistency */
  margin-right: 20px;
  cursor: pointer;
}
.dropdown-full .dropdown-content-full {
  display: none;
  width: 100%; /* Full width */
  background-color: white;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);
  position: absolute;
  left: 0;
  border-top: none;
}
.nav-item:hover .dropdown-content-full {
  display: block; /* Show dropdown on hover */
}

.dropdown-teachers .dropdown-content-teachers {
  display: none;
  width: 100%; /* Full width */
  background-color: white;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);
  position: absolute;
  left: 0;
  border-top: none;
}
.nav-item:hover .dropdown-content-teachers {
  display: block; /* Show dropdown on hover */
}
.nav-item:hover .nav-link {
  border-bottom: 2px solid #17204a; /* Border bottom on hover */
}
.title {
  border-bottom: 2px solid #17204a;
  padding-bottom: 5px;
}
.dropdown-logo {
  margin-top: 20px;
  width: 100px; /* Adjust size as needed */
}
.box {
  padding: 20px;
}
.dropdown-menu{
  left: -50px;
  text-align: center;
  padding: 0px;
}
.dropdown-menu span{
  font-size: 12px;
}
.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-menu:hover {
  display: block;
}
.dropdown-menu li{
  padding: 20px;
}
.dropdown{
  cursor: pointer;
  font-size: 25px;
  margin-right: 20px;
  text-align: center;
}

.dropdown ul li:hover{
  background-color: #17204a;
  color: white;
  text-align: center;
}
.btn-outline-success{
  border-color: #17204a;
  color: #17204a;
  border-radius: 30px;
}
.btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active{
  background-color: #17204a;
  color: white;
}
.view-plans{
  display: inline-block;
  margin-left: 20px;
}
</style>
