import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/components/HomePage';
import PlansAndProducts from '@/components/PlansAndProducts';
import PrivacyPolicy from '@/components/PrivacyPolicy.vue';
import CookiesPage from '@/components/CookiesPage.vue';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    { path: '/', name: 'Home', component: Home },
    { path: '/plans-and-products', name: 'PlansAndProducts', component: PlansAndProducts },
    { path: '/privacy-policy', name: 'PrivacyPolicy', component: PrivacyPolicy },
    { path: '/cookies', name: 'CookiesPage', component: CookiesPage }
  ]
});

export default router;
