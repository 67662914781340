<script>
// import HomePage from './components/HomePage.vue';
import MainNavBar from './components/MainNavBar.vue';
import FooterBar from './components/Footer.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

export default {
  name: 'App',
  components: {
    MainNavBar,
    FooterBar
  }
};
</script>

<template>
  <div id="app">
    <MainNavBar />
    <main>
      <router-view />
    </main>
    <FooterBar />
  </div>
</template>

<style>
/* Your styles here */
</style>
