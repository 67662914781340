<template>
  <div class="home-intro">
    <div class="container">
      <h1>Practise your English anywhere.</h1>
      <p>Learn to perfect your speech and build confidence in your own voice by seeing sounds. Use EduSay to say anything in English, from idioms to phrases, and personalised learning and generative AI tools powered by EduSay.</p>
      <button class="btn btn-primary" @click="startFreeTrial">Free trial</button>
      <a href="#" class="view-plans">View all plans</a>
    </div>
  </div>


  <div class="info-boxes">
    <div class="container">
      <div class="row">
        <!-- Box 1 -->
        <div class="col-md-4">
          <div class="box">
            <img src="@/assets/image1.png" alt="Description of Image 1" class="box-image">
            <button class="btn btn-primary" @click="startTrial">Start trial</button>
          </div>
        </div>
        <!-- Box 2 -->
        <div class="col-md-4">
          <div class="box">
            <img src="@/assets/image2.png" alt="Description of Image 2" class="box-image">
            <button class="btn btn-primary" @click="registerInterest">Register interest</button>
          </div>
        </div>
        <!-- Box 3 -->
        <div class="col-md-4">
          <div class="box">
            <img src="@/assets/image3.png" alt="Description of Image 3" class="box-image">
            <button class="btn btn-primary" @click="getEduSayFree">Get EduSay free</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'HomePage',
  methods: {
    startFreeTrial() {
      // Implement your logic to start a free trial
      alert('Starting free trial...');
    }
  }
};
</script>

<style scoped>
.home-intro {
  width: 100%;
  padding: 2rem 0;
  background-color: #f8f9fa; /* Light grey background */
}
.container {
  max-width: 1200px;
  margin: auto;
  padding: 0 15px;
}
h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: #17204a;
}
p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.btn-primary {
  background-color: #17204a; /* Bootstrap primary blue */
  border-color: #17204a;
  color: white;
  padding: 0.5rem 1.5rem;
  font-size: 1.2rem;
  cursor: pointer;
}
.view-plans {
  display: block;
  color: #000;
  text-decoration: underline;
  margin-top: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
}

.info-boxes .box {
  text-align: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  margin-bottom: 20px;
  margin-top: 20px
}
.box-image {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}
.info-boxes{
  margin-top: 20px;
}

</style>
